* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

body {
    font-family: 'DM Sans', sans-serif;
}

.projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #F5F5F5;
    position: relative;
    overflow: hidden;
}

.page-title {
    font-family: 'Abhaya Libre', serif;
    font-weight: 600;
    font-size: 52px;
    margin-bottom: 30px;
    color: #000;
    z-index: 2;
}

.main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: relative;
}

.logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.logout-button:hover {
    background-color: #d32f2f;
}


.projects-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-top: 20px;
    width: 90vw;
    height: 60vh;
    overflow-y: auto;
    z-index: 2;
}

.project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    text-align: center;
}

.project-box, .create-box {
    width: 218px;
    height: 209px;
    background-color: rgba(196, 196, 196, 0.29);
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    transition: border 0.3s ease;
}

.project-box .checkmark {
    display: none;
    color: white;
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.project-box.selected .checkmark {
    display: block;
}

.project-box.selectable {
    border: 2px solid #7FC8F8;
}

.project-box.selected {
    border: 2px solid #7FC8F8;
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.custom-checkbox {
    display: none;
}

.project-title {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #000;
    margin-top: 10px;
}

.project-description {
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #777;
    margin-top: 5px;
    width: 80%;
}

.create-project-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.create-text {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #000;
    margin-top: 10px;
}

.fog1, .fog2, .fog3, .fog4 {
    position: absolute;
    border-radius: 50%;
    filter: blur(100px);
    z-index: 1;
}


.fog1 {
    width: 350px;
    height: 300px;
    background-color: #FFE45E;
    left: -80px;
    top: 90%;
}

.fog2 {
    width: 350px;
    height: 350px;
    background-color: #7FC8F8;
    left: 150px;
    top: 100%;
}

.fog3 {
    width: 350px;
    height: 350px;
    background-color: #7FC8F8;
    right: -70px;
    top: 5%;
}

.fog4 {
    width: 300px;
    height: 300px;
    background-color: #FF6392;
    right: -150px;
    top: 10%;
}

.input-box {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
    border: 1px solid #7FC8F8;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
}

.input-box:focus {
    border-color: #FF6392;
}


.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.styled-button {
    width: 100px;
    height: 40px;
    background-color: #7FC8F8;
    border: none;
    border-radius: 10px;
    color: black;
    font-family: 'DM Sans', sans-serif;
    font-weight: 300;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.styled-button:hover {
    background-color: #66b3e0;
}

.cancel-button {
    background-color: #FF6392;
}

.cancel-button:hover {
    background-color: #e55780;
}


.project-modal {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    z-index: 1000;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.action-buttons .styled-button {
    margin-right: 10px;
}

.action-buttons .styled-button:last-child {
    margin-right: 0;
}

.edit-input {
    font-family: 'DM Sans', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: #000;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: center;
    width: auto;
    min-width: 80px;
    cursor: text;
}

.edit-input::placeholder {
    color: #777;
    font-weight: 300;
}

.edit-input:focus {
    outline: none;
    border-bottom: 1px solid #FF6392;
}
