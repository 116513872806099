.react-flow .react-flow__handle-bottom {
    width: 20px;
    height: 20px;
    background-color: #5AA9E6;
    border-radius: 50%;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
}


.react-flow .react-flow__handle-top {
    visibility: hidden

}


.react-flow .react-flow__handle-bottom::before,
.react-flow .react-flow__handle-bottom::after {
    content: "";
    position: absolute;
    background-color: white;
    border-radius: 1px;
}

.react-flow .react-flow__handle-bottom::before {
    width: 60%;
    height: 3px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.react-flow .react-flow__handle-bottom::after {
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.react-flow .react-flow__node {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 0;
    gap: 0;
    width: 145px;
    box-sizing: border-box;
}

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
    stroke: rgba(0, 0, 0, 0.2);
    stroke-width: 1;
    stroke-linecap: round;
}

.wrapper {
    flex-grow: 1;
    height: 100%;
}

.react-flow__node.selected {
    border-color: #FF6392;
    border-width: 2px;
}

.flag-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.flag-bubble {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    font-size: 16px;
}

.collapsed-node-content {
    display: inline-block;
    white-space: nowrap;
    padding: 4px 0;
    overflow: hidden;
    text-overflow: ellipsis;
}