.flow-page-container {
    width: 100vw;
    height: 100vh;
    position: relative;
}


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 130px;
    background-color: transparent;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    pointer-events: none;
}


.header-title {
    font-family: 'Abhaya Libre', serif;
    font-weight: 600;
    font-size: 30px;
    color: black;
    pointer-events: all;
}


.flow-content {
    position: relative;
    top: 0;
    height: 100vh;
    width: 100vw;
}

.header-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: all;
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: all;
}

.icon {
    font-size: 2.8rem;
    color: black;
    cursor: pointer;
    padding: 10px;
}

.icon:hover {
    color: #7FC8F8;
    transform: scale(1.1);
}

.home-icon {
    margin-right: auto;
    color: #4a4a4a;
}

.search-icon {
    margin-left: 10px;
    color: #4a4a4a;
    font-size: 2.9rem;

}

.info-icon {
    margin-left: 10px;
    color: #4a4a4a;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.dropdown-guide {
    position: absolute;
    top: 100%;
    right: 55px;
    background-color: white;
    border: 2px solid #ff66b2;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    font-family: 'Abhaya Libre', serif;
    width: 300px;
}

.guide-slide h4 {
    margin: 0 0 10px;
    font-size: 1.2rem;
    color: #333;
    font-style: bold;
}

.guide-slide p {
    margin: 0 0 15px;
    font-size: 0.95rem;
    color: #666;
}

.guide-controls {
    display: flex;
    justify-content: space-around;
}

.guide-controls button {
    padding: 5px 15px;
    margin: 5px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: bold;
    color: white;
    font-size: 0.9rem;
    transition: background-color 0.2s ease;
}

.guide-controls .prev-btn {
    background-color: #4d9eea;
}

.guide-controls .next-btn {
    background-color: #FF6392;
}

.prev-btn.disabled,
.next-btn.disabled {
    background-color: #d3d3d3;
    color: #6c757d;
    cursor: not-allowed;
    opacity: 0.6;
}