body {
    font-family: 'Abhaya Libre', sans-serif;
}

.page-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

.picture-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.picture-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-container {
    z-index: 2;
    width: 100%;
    max-width: 450px;
    padding: 40px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 20px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.form-container h2 {
    font-size: 28px;
    margin-bottom: 1.5rem;
    color: #333;
    font-weight: 600;
}

.inputs {
    margin: 20px 0;
}

.input {
    margin-bottom: 15px;
}

.input-field {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 30px;
    font-size: 10px;
    color: #333;
    background-color: #f9f9f9;
    outline: none;
    transition: border-color 0.3s;
}

.input-field:focus {
    border-color: #5AA9E6;
}

.button {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(135deg, #5AA9E6, #4a98c6);
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.button:hover {
    background: linear-gradient(135deg, #4a98c6, #3b7fbf);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.other-button {
    background: linear-gradient(135deg, #7FC8F8, #6ab8e0);
    color: #fff;
    width: 100%;
    padding: 12px;
    font-size: 12px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.other-button:hover {
    background: linear-gradient(135deg, #6ab8e0, #5a9bcf);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

.divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: #ccc;
    font-size: 10px;
}

.divider::before,
.divider::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
    margin: 0 10px;
}

.error {
    padding-bottom: 20px;
    font-size: 12px;
    color: #FF6392;
}

.description {
    opacity: 70%;
    font-size: 12px;
}
