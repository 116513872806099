.search-bar-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-bar-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.search-bar-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.search-bar-button:hover {
    background-color: #0056b3;
}
