.landing-page {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: relative;
    font-family: 'Abhaya Libre', serif;
    overflow: hidden;
    animation: fadeInPage 0.5s forwards;
}

@keyframes fadeInPage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.landing-page h1 {
    font-size: 5rem;
    z-index: 1;
    margin: 0;
}

.fog {
    position: absolute;
    border-radius: 50%;
    filter: blur(60px);
    opacity: 0;
    animation: fadeIn 1s forwards;
    z-index: 0;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.5;
    }
}

.fade-out {
    animation: fadeOut 1s forwards;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/* Colors and positions for fog circles */
.fog1 {
    width: 300px;
    height: 300px;
    background-color: #FF6392;
    left: 30%;
    top: calc(50% - 20px);
    transform: translateY(-50%);
    animation-delay: 0s;
}

.fog2 {
    width: 350px;
    height: 350px;
    background-color: #7FC8F8;
    left: 50%;
    top: calc(50% + 10px);
    transform: translate(-50%, -50%);
    animation-delay: 0.2s;
}

.fog3 {
    width: 300px;
    height: 300px;
    background-color: #FFE45E;
    right: 30%;
    top: calc(50% - 20px);
    transform: translateY(-50%);
    animation-delay: 0.4s;
}