.parameter-container {
    margin-top: 0px;
    margin-bottom: 0px;
}

.outcome-container {
    margin-top: 0px;
    margin-bottom: 0px;
}

.parameter-row,
.outcome-row {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 0;
    padding: 0 0;
}

.parameter,
.outcome {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    flex: 1;
}

.outcome-save-button {
    width: 35px;
    height: 15px;
    font-size: 4px;
    border-radius: 5px;
    color: black;
    border: none;
    cursor: pointer;
}

.hover-mean {
    position: absolute;
    left: 71.5px;
    font-size: 8px;
    color: #FF6392;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 0 2px 2px #FF6392;
    background-color: white;
    z-index: 1001;
    height: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hover-mean-tip {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #FF6392 transparent transparent transparent;
}

.border-input {
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    padding: 5px 3px;
    margin: 0;
    text-align: left;
    width: 100%;
    height: auto;
    font-size: 8px;
    background-color: transparent;
    box-shadow: none;
}

.value-container {
    background: transparent;
    border: 0.5px solid rgba(0, 0, 0, 0.1);
    width: 71.5px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 5px 2px;
    margin: 0;
    overflow: hidden;
    transition: height 0.3s ease;
}

.border-input:focus {
    outline: none;
    border-color: rgba(255, 99, 146, 0.3);
    box-shadow: 0 0 0 2px rgba(255, 99, 146, 0.3);
    transition: box-shadow 0.2s ease, border-color 0.2s ease;
}


.value-container.expanded {
    height: auto;
    flex-wrap: wrap;
}

.value-item {
    display: flex;
    align-items: center;
    border: none;
    border-radius: 12px;
    padding: 1px;
    margin: 1px;
    font-size: 8px;
    gap: 1px;
    height: auto;
    max-width: 100%;
}

.outcome-row-content {
    display: flex;
    align-items: center;
    gap: 0px;
}

.add-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 71.5px;
    height: 22.5px;
}

.add-value input {
    font-size: 8px;
    border: none;
    background: none;
    padding: 2px;
    text-align: left;
    outline: none;
    color: black;
    width: 66.5px;
    height: 20.5px;
}

.add-value input:focus {
    outline: none;
    border-color: rgba(255, 99, 146, 0.3);
    box-shadow: 0 0 0 2px rgba(255, 99, 146, 0.3);
    transition: box-shadow 0.2s ease, border-color 0.2s ease;
}

.value-text {
    font-size: 8px;
    font-weight: 500;
    color: #333;
}

.remove-value-btn {
    color: gray;
    border: none;
    background: transparent;
    border-radius: 60%;
    cursor: pointer;
    font-size: 5px;
}

.suggestions-dropdown {
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    top: 100%;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    padding: 0;
    margin: 0;
    list-style: none;
    border-radius: 4px;
    font-size: 8px;
}


.suggestions-dropdown li {
    padding: 8px 10px;
    cursor: pointer;
}

.suggestions-dropdown li:hover, .suggestions-dropdown li.selected {
    background-color: #D9D9D9;
}