.toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border-radius: 40px;
    z-index: 1000;

    background: white; /* Background color of the toolbar */
    border: 2px solid transparent; /* Make the border transparent initially */
    background-image: linear-gradient(white, white), linear-gradient(to right, #FF6392, #7FC8F8, #FFE45E); /* Gradient border */
    background-origin: border-box;
    background-clip: padding-box, border-box; /* Apply gradient border */
}

.leftButtons, .rightButtons {
    margin: 0 12px;
}

.toolbar button {
    font-size: 10px;
    background-color: #ffffff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 2px;
}
.toolbar button svg {

    font-size: 24px;
    color: #1e1e1e; /* Icon color */
}


.colorMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #ffe45e;
    padding: 12px 12px;
    top: 160%;
    right: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.colorButtons {
    display: grid;
    grid-template-columns: repeat(3, 25px);
    grid-gap: 10px;
}
.noColor {
    display: flex; /* Ensure flex to center */
    justify-content: center; /* Center horizontally */
    margin-top: 10px; /* Space from color buttons */
    margin-right: 4px;
}
.colorButton {
    width: 25px;
    height: 25px;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 50%;
    transition: transform 0.2s ease;
}
.colorButton svg {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: transform 0.2s ease;
}
.colorButton:hover {
    transform: scale(1.1);
}

.flagMenu {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid #ffe45e;
    padding: 12px;
    top: 160%;
    right: 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
.flagButtons {
    display: grid;
    grid-template-columns: repeat(5, 25px); /* Display the buttons in a grid like the color menu */
    grid-gap: 10px;
}
.flagButton svg{
    width: 25px;
    height: 25px;
    border: none;
    cursor: pointer;
    outline: none;
    border-radius: 50%; /* Same as the color buttons */
    transition: transform 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flagButton:hover {
    transform: scale(1.1); /* Same hover effect as the color buttons */
}

.confirmationPopup {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 5px;
    background-color: white;
    border: 2px solid #ff66b2;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    font-family: Arial, sans-serif;
}

.confirmationPopup button {
    padding: 5px 15px;
    margin: 5px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-weight: bold;
    color: white;
}

.confirmationPopup .yes-button {
    background-color: #4d9eea;
}

.confirmationPopup .no-button {
    background-color: #FF6392;
}

