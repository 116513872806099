.modal-overlay {
    position: fixed;
    top: 170px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 1px 4px 4px #FF6392;
    background-color: white;
    overflow: hidden;
    position: absolute;
    max-height: 80vh;
    width: 260px;
    z-index: 1001;
}

.modal-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d3d3d3;
    padding: 10px;
    cursor: move;
}

.modal-top-bar button {
    margin-left: auto;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
}

.modal-top-bar button:hover {
    color: #c94d73;
}

.modal-content {
    padding: 0 18px 10px 18px;
    font-size: 14px;
    max-height: 80vh;
    overflow-y: auto;
}

.modal-content h2 {
    font-size: 18px;
    margin-top: 20px;
}



.outcomes-table,
.parameters-table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

td:nth-child(1) {
    width: 70%;
}
td:nth-child(2) {
    width: 30%;
}

.outcomes-table th,
.outcomes-table td,
.parameters-table th,
.parameters-table td {
    padding: 10px;
    text-align: left;
}

.outcomes-table th,
.parameters-table th {
    background-color: #f2f2f2;
    color: #333;
}

.outcomes-table tr:nth-child(even),
.parameters-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.outcomes-table tr:hover,
.parameters-table tr:hover {
    background-color: #e0e0e0;
}
